<template>
  <b-card-body>
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="catalogSearch.keyword"
            placeholder="Katalog Adı Arama..."
            @keydown.enter="searchData"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchData"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-button
          variant="primary"
          :to="$route.path + '/add'"
        >
          <FeatherIcon icon="PlusIcon" /> Oluştur
        </b-button>
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import {
  BCardBody, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  name: 'IndexHeader',
  components: {
    BCardBody, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    catalogSearch() {
      return this.$store.getters['catalogs/catalogSearch']
    },
  },
}
</script>
