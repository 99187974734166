<template>
  <b-card no-body>
    <index-header :search-data="searchData" />
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(customer)="data">
        <div>
          {{ data.item.customer }}
        </div>
        <div class="font-small-2 text-primary">
          {{ data.item.title }}
        </div>
        <div class="font-small-2 font-italic text-muted">
          <template v-if="data.item.modified">
            {{ moment(data.item.modified).format('DD.MM.YYYY') }} tarihinde {{ data.item.username }} tarafından güncellendi.
          </template>
          <template v-else-if="data.item.created">
            {{ moment(data.item.created).format('DD.MM.YYYY') }} tarihinde {{ data.item.username }} tarafından oluşturuldu.
          </template>
        </div>
      </template>
      <template #cell(expiry_date)="data">
        <div>
          {{ moment(data.item.expiry_date).format('DD.MM.YYYY') }}
        </div>
        <div class="font-small-2 text-warning">
          <span v-if="moment(data.item.expiry_date).diff(moment(),'days') > 0">
            {{ moment(data.item.expiry_date).diff(moment(),'days') }} gün kaldı.
          </span>
          <span
            v-else
            class="text-danger"
          >
            Süre doldu.
          </span>
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <list-buttons
            primary-text="Görüntüle"
            :primary-action="$route.path + '/view/' + data.item.id"
            :edit-action="$route.path + '/edit/' + data.item.id"
            :delete-action="removeData"
            :data-id="data.item.id"
          />
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BPagination, BCardFooter,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IndexHeader from '@/views/Admin/Catalogs/elements/Index/IndexHeader.vue'

const tableName = 'catalogs'
export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    ListButtons,
    IndexHeader,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'title',
          label: 'KATALOG ADI',
        },
        {
          key: 'catalog_status',
          label: 'DURUM',
          thClass: 'text-nowrap text-center width-200',
          tdClass: 'text-nowrap text-center width-200',
        },
        {
          key: 'expiry_date',
          label: 'GEÇERLİLİK TARİHİ',
          thClass: 'text-nowrap width-200',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.title AS title`,
          `${tableName}.expiry_date AS expiry_date`,
          'catalog_statuses.title AS catalog_status',
          'users.name AS username',
          `${tableName}.created AS created`,
          `${tableName}.modified AS modified`,
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['catalogs/dataList']
    },
    dataCounts() {
      return this.$store.getters['catalogs/dataCounts']
    },
    catalogSearch() {
      return this.$store.getters['catalogs/catalogSearch']
    },
    saveData() {
      return this.$store.getters['catalogs/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('catalogs/getDataList', this.dataQuery)
    },
    searchData() {
      this.dataQuery.start = 0
      this.currentPage = 1
      if (this.catalogSearch.keyword) {
        this.dataQuery.or_like = {
          'catalogs.title': this.catalogSearch.keyword,
        }
      } else {
        this.dataQuery.or_like = {}
      }
      this.getDataList()
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('catalogs/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
